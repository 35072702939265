
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { GET_CUSTOMERS_ORDER_REPORT, GET_CUSTOMERS_INVOICE_REPORT, SEARCH_ORDERS_CUSTOMER, SEARCH_INVOICE_CUSTOMER } from '../graphql/Queries';
import { generateAvatar } from '@/core/helpers/generateAvatar';
import Table from '../../../../components/Table/Table.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { Apollo } from '@/core/services';
import AdvanceSearch from '../../../../components/search/AdvanceSearch.vue';
import { setPageHistory } from '@/core/helpers/toolbar';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        Table,
        InnerLoader,
        AdvanceSearch
    },

    setup() {
        const loader = ref(true);
        const store = useStore();
        const pagination = ref({}) as Record<any, any>;
        const reports: any = ref([]);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref(0);
        const search_customer_query = ref(false);
        const activeIndex = ref(-1);
        const showDelete = ref(false);
        const filterOption = ref({}) as Record<any, any>;
        const search_user_orders = ref(false);
        const router = useRouter();
        const system_locale = ref();
        const i18n = useI18n();
        const loading = ref(false);
        const columnName = ref(null);
        const columnSort = ref(null);

        filterOption.value = {
            name: true,
            customer_report_email: true,
            customer_report_status: true
        };

        const columns = ref([
            {
                label: '',
                key: 'avatar'
            },
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.EMAIL',
                key: 'email',
                sorting: true
            },
            {
                label: 'message.LAST_ORDER_DATE',
                key: 'modified_date',
                sorting: true
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const statusList = ref([
            {
                label: 'message.ALL',
                value: 'all'
            },
            {
                label: 'message.ACTIVE',
                value: 1
            },
            {
                label: 'message.INACTIVE',
                value: 0
            }
        ]);

        const getCustomersInvoiceReport = (page = 1, reload = false, colName = null, type = null) => {
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            search_user_orders.value = false;
            search_customer_query.value = false;
            const sub = Apollo.watchQuery({
                query: GET_CUSTOMERS_INVOICE_REPORT,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    showDeleted: showDelete.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                console.log('data :>> ', data);
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getCustomersInvoiceReport;
                reports.value = data?.customers_invoice_report?.data;
                pagination.value = data?.customers_invoice_report;
                loader.value = false;
                sub.unsubscribe();
            });
        };

        // const getCustomersOrderReport = (page = 1, reload = false, colName = null, type = null) => {
        //     loader.value = true;
        //     columnName.value = null;
        //     columnSort.value = null;
        //     search_user_orders.value = false;
        //     search_customer_query.value = false;
        //     const sub = Apollo.watchQuery({
        //         query: GET_CUSTOMERS_ORDER_REPORT,
        //         errorPolicy: 'all',
        //         fetchPolicy: 'network-only',
        //         nextFetchPolicy: 'cache-only',
        //         variables: {
        //             page: page,
        //             limit: 10,
        //             showDeleted: showDelete.value,
        //             col: columnName.value,
        //             type: columnSort.value == true ? 'asc' : 'desc'
        //         }
        //     }).subscribe(({ data, errors }) => {
        //         if (errors) {
        //             loader.value = false;
        //         }

        //         reload == true ? (currentPage.value = 1) : false;
        //         clickHandler.value = getCustomersOrderReport;
        //         reports.value = data?.customers_order_report?.data;
        //         pagination.value = data?.customers_order_report;
        //         loader.value = false;
        //         sub.unsubscribe();
        //     });
        // };

        const reloadReportListing = () => {
            showDelete.value = false;
            getCustomersInvoiceReport();
        };

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const searchHandler = (page = 1, reload = false, colName = null, type = null) => {
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            search_customer_query.value = true;
            const searchData = store.getters.getSearchData;
            loader.value = true;
            const search = Apollo.watchQuery({
                query: SEARCH_INVOICE_CUSTOMER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: JSON.stringify(searchData?.data?.select),
                    search_key: searchData?.data?.input,
                    locale: system_locale.value,
                    showDeleted: showDelete.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                reports.value = data?.search_invoice_customer?.data;
                pagination.value = data?.search_invoice_customer;
                loader.value = false;
                search.unsubscribe();
            });
        };

        // const searchHandler = (page = 1, reload = false, colName = null, type = null) => {
        //     if (colName != null) columnName.value = colName;
        //     if (type != null) columnSort.value = type;
        //     search_customer_query.value = true;
        //     const searchData = store.getters.getSearchData;
        //     loader.value = true;
        //     const search = Apollo.watchQuery({
        //         query: SEARCH_ORDERS_CUSTOMER,
        //         errorPolicy: 'all',
        //         fetchPolicy: 'network-only',
        //         nextFetchPolicy: 'cache-only',
        //         variables: {
        //             page: page,
        //             limit: 10,
        //             filter: JSON.stringify(searchData?.data?.select),
        //             search_key: searchData?.data?.input,
        //             locale: system_locale.value,
        //             showDeleted: showDelete.value,
        //             col: columnName.value,
        //             type: columnSort.value == true ? 'asc' : 'desc'
        //         }
        //     }).subscribe(({ data, errors }) => {
        //         if (errors) {
        //             loader.value = false;
        //         }

        //         clickHandler.value = searchHandler;
        //         reports.value = data?.search_orders_customer?.data;
        //         pagination.value = data?.search_orders_customer;
        //         loader.value = false;
        //         search.unsubscribe();
        //     });
        // };

        const lastOrderplaced = dateString => {
            const today = new Date();
            const getMonth = today.getMonth() + 1;

            const parts = dateString?.split('-');
            if (parts.length === 3) {
                const month = parseInt(parts[1]);

                if (getMonth === month) {
                    return '🟢';
                } else if (getMonth === month + 1) {
                    return '🟡';
                } else {
                    return '🔴';
                }
            }
        };

        const handleView = (val: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });

            router.push({
                name: 'customer_detail',
                params: { uuid: val.uuid, orderTab: 'true' }
            });
        };

        const handleOrderView = (val: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });

            router.push({
                name: 'order_details',
                params: { uuid: val.uuid }
            });
        };

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loader.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_ORDERS_CUSTOMER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? JSON.stringify(searchData.data.select) : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    types: data?.type,
                    locale: system_locale.value,
                    showDeleted: showDelete.value,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                } else if (data) {
                    window.open(data?.search_orders_customer?.data[0]?.path, '_blank');
                    loader.value = false;
                }
                loader.value = false;
                sub.unsubscribe();
            });
        };

        const handleCurrentChange = (page: number) => {
            clickHandler.value(page);
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };

        const changeShowDelete = () => {
            const pageHistory = store.getters.getPageHistory;
            searchHandler(pageHistory.page_number, false, columnName.value, columnSort.value);
        };

        onMounted(() => {
            getCustomersInvoiceReport();
        });

        return {
            generateAvatar,
            getCustomersInvoiceReport,
            reports,
            columns,
            dropdownHandler,
            activeIndex,
            showDelete,
            pagination,
            filterOption,
            statusList,
            handleView,
            lastOrderplaced,
            searchHandler,
            reloadReportListing,
            handleOrderView,
            exportFile,
            loader,
            handleCurrentChange,
            columnName,
            columnSort,
            changeShowDelete
        };
    }
});
