import { gql } from "graphql-tag";

export const INVOICE_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    path
    user {
      id
      uuid
      name
      email
      status

      image {
        id
        file_path

      }
    }
    invoices {
      customer_id
      id
      created_at
      modified_date
    }

  }
`;

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    path
    user {
      id
      uuid
      name
      email
      status

      image {
        id
        file_path

      }
    }
    orders {
      customer_id
      id
      uuid
      created_at
    }

  }
`;

export const PAGINATION = gql`
  fragment Pagination on CustomerPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export default { CUSTOMER_FIELDS, PAGINATION, INVOICE_FIELDS };