
import { defineComponent, onMounted } from "vue";
import CustomersOrderList from "../components/CustomersOrderList.vue";
import { setPageHeader } from "@/core/helpers/toolbar";
export default defineComponent({
  components: {
    CustomersOrderList,
  },
  setup() {
    onMounted(() => {
      setPageHeader({
        title: "message.CUSTOMERS_REPORTS",
        actionButton: {
          ability: "",
          pageAction: {
            action: "",
          },
          button: {},
        },
        breadCrumbs: [
          { name: "message.HOME", link: "/dashboard" },
          { name: "message.REPORTS", link: "#" },
          { name: "message.CUSTOMERS_ORDER_REPORT" },
        ],
      });
    });
  },
});
