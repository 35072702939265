import { gql } from 'graphql-tag';
import { CUSTOMER_FIELDS, PAGINATION, INVOICE_FIELDS } from './Fragments';


export const GET_CUSTOMERS_ORDER_REPORT = gql`
    query GetCustomersReport($page: Int, $limit: Int, $showDeleted: Boolean) {
        customers_order_report(limit:$limit, page:$page, showDeleted: $showDeleted){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_FIELDS},
    ${PAGINATION},
`;

export const GET_CUSTOMERS_INVOICE_REPORT = gql`
    query GetCustomersInvoice($page: Int, $limit: Int, $showDeleted: Boolean) {
        customers_invoice_report(limit:$limit, page:$page, showDeleted: $showDeleted){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${INVOICE_FIELDS},
    ${PAGINATION},
`;



export const SEARCH_ORDERS_CUSTOMER = gql`
    query SearchOrdersCustomer($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $types:String, $showDeleted: Boolean, $col: String, $type: String, $date_to:String, $date_from:String) {
        search_orders_customer(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, types:$types,  showDeleted:$showDeleted, col:$col, type:$type,  date_to:$date_to, date_from:$date_from){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_INVOICE_CUSTOMER = gql`
    query SearchInvoiceCustomer($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $types:String, $showDeleted: Boolean, $col: String, $type: String, $date_to:String, $date_from:String) {
        search_invoice_customer(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, types:$types,  showDeleted:$showDeleted, col:$col, type:$type,  date_to:$date_to, date_from:$date_from){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${INVOICE_FIELDS},
    ${PAGINATION},
`;

export default { GET_CUSTOMERS_ORDER_REPORT, SEARCH_ORDERS_CUSTOMER, GET_CUSTOMERS_INVOICE_REPORT, SEARCH_INVOICE_CUSTOMER };
